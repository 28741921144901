<template>
  <div class="parkingGuide needsclick">
    <header>
      <div>
        <img @click="handleBack" src="@/assets/imgs/back-icon.png" alt="" />
        <span>风险描述</span>
        <!-- <span v-else>新增风险描述</span> -->
      </div>
    </header>

    <div class="addressInfo">
      <img
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/address.png"
        alt=""
      />
      <div>
        <div>{{ stationName }}</div>
        <div>ID {{ stationId }}</div>
      </div>
    </div>
    <div v-if="noneData && !isEdit" class="noneData">
      <img
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/noneData.png"
        alt=""
      />
      <div>暂无风险描述</div>
      <div></div>
      <div @click="isEdit = true">上传描述</div>
    </div>
    <div v-else class="guideCard">
      <div v-if="isEdit" class="uploadTips">
        <span>上传/拍摄图片</span>
        <span>(最多{{ max }}张)</span>
        <!-- <van-popover
          v-model="showPopover"
          trigger="click"
          placement="bottom-start"
          theme="dark"
          get-container=".parkingGuide"
        >
          <span class="tipContent"></span>
          <template #reference>
            <img
              class="needsclick"
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/question.png"
              alt=""
            />
          </template>
        </van-popover> -->
      </div>
      <div v-for="(item, index) in list" :key="item.key" class="uploadListItem">
        <div class="uploadedPic">
          <div v-if="item.loading" class="uploadLoading">
            <img
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/loading.png"
              alt=""
            />
          </div>
          <template v-if="item.url">
            <img
              v-if="isEdit"
              class="needsclick"
              @click="handleUploadClick($event, index)"
              :src="item.url"
              alt=""
            />
            <img
              v-else
              class="needsclick"
              @click="handleImagePreview(index)"
              :src="item.url"
              alt=""
            />
          </template>
        </div>
        <div class="description">
          <textarea
            @input="handleDescriptionChange($event, index)"
            v-if="isEdit"
            :value="item.description"
            placeholder="可添加文字描述，最多50个字"
            maxlength="50"
          ></textarea>
          <div v-else :class="{ noneDesc: !item.description }">
            {{ item.description || '暂无描述' }}
          </div>
        </div>
        <div v-if="isEdit" class="deleteUploaded">
          <img
            @click="handleDeleteItem(index, item.url)"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/item_delete.png"
            alt=""
          />
        </div>
      </div>
      <div v-if="isEdit && list.length < max" class="uploadTrigger">
        <img
          class="needsclick"
          @click="handleUploadClick"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/uploader.png"
          alt=""
        />
      </div>
    </div>
    <div v-if="isEdit" class="bottomBtn submitBtn">
      <div v-if="!changed" class="disabled">提交</div>
      <div v-else @click="submitUpload">提交</div>
    </div>
    <div v-if="!noneData && !isEdit" class="bottomBtn editBtn">
      <div @click="isEdit = true">编辑</div>
    </div>
    <van-uploader
      :before-read="onBeforeRead"
      :after-read="onAfterRead"
      ref="uploader"
      :max-count="1"
      ><span></span
    ></van-uploader>
  </div>
</template>

<script>
import NDialog from '@/components/NDialog';
import { ImagePreview } from 'vant';
import {
  uploadPicture,
  // deletePicture,
  getRiskPoiInfo,
  batchInsertRiskPicture,
} from '@/api/apiv2';

export default {
  components: {},
  data() {
    return {
      stationId: null,
      stationName: null,
      noneData: false,
      list: [],
      isEdit: false,
      activeIndex: null,
      uploadFrom: 0, // 0 create; 1 update
      showPopover: false, // 提示
      changed: false, // 是否编辑过
      max: 3,
    };
  },
  watch: {
    isEdit(newValue) {
      if (newValue) {
        this.stopWatchChange = this.$watch(
          'list',
          () => {
            this.changed = true;
          },
          { deep: true }
        );
      } else {
        this.stopWatchChange();
        this.changed = false;
      }
    },
    list(value) {
      this.noneData = value.length === 0;
    },
  },
  methods: {
    handleBack() {
      this.$router.back();
    },
    async getList() {
      const res = await getRiskPoiInfo(this.stationId);
      this.list = res.pictureList;
    },
    handlePopoverClick() {
      this.showPopover = true;
    },
    handleUploadClick(event, index) {
      if (index !== undefined) {
        this.activeIndex = index;
        this.uploadFrom = 1;
      } else {
        this.activeIndex = this.list.length;
        this.uploadFrom = 0;
      }
      const uploaderIpt = this.$refs['uploader'].$el.querySelector('input[type=file]');
      uploaderIpt.click();
    },
    async onAfterRead(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      const url = await uploadPicture(formData);
      this.$set(this.list[this.activeIndex], 'url', url);
      this.$set(this.list[this.activeIndex], 'loading', false);
    },
    async onBeforeRead() {
      if (!this.uploadFrom) {
        this.list.push({
          url: '',
          loading: true,
          description: '',
          stationId: this.stationId,
          key: performance.now(),
        });
      } else {
        this.$set(this.list[this.activeIndex], 'loading', true);
      }
    },
    handleDescriptionChange(event, index) {
      this.$set(this.list[index], 'description', event.target.value);
    },
    handleDeleteItem(index) {
      this.list.splice(index, 1);
      // deletePicture({ url }).then(() => {
      //   this.$toast("删除成功！");
      // });
    },
    async submitUpload() {
      const result = await NDialog.confirm({
        message: '确定提交风险描述吗?',
      });
      if (result === 'confirm') {
        await batchInsertRiskPicture({
          poiPictures: this.list.map((item) => ({
            specialId: this.stationId,
            ...item,
          })),
        });
        this.$toast('提交成功');
        this.isEdit = false;
      }
    },
    handleImagePreview(startPosition) {
      const images = this.list.map((item) => item.url);
      ImagePreview({ images, startPosition });
    },
  },
  computed: {},
  created() {
    const { stationId, stationName } = this.$route.query;
    this.stationId = stationId;
    this.stationName = stationName;
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.parkingGuide {
  font-size: initial;
  position: relative;
  height: 100vh;
  header {
    background: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    padding-top: var(--appBarHeight);
    > div {
      width: 100%;
      height: 40px;
      line-height: 40px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .addressInfo {
    background: white;
    display: flex;
    padding: 8px 12px;
    img {
      width: 22px;
      height: 22px;
      margin-right: 2px;
    }
    > div {
      > div {
        &:nth-child(1) {
          font-size: 16px;
          font-weight: 400;
        }
        &:nth-child(2) {
          font-size: 14px;
          font-weight: 400;
          color: rgba(151, 152, 171, 1);
        }
      }
    }
  }

  ::v-deep .van-popover__content {
    padding: 10px;
    width: 176px;
    .tipContent {
      box-sizing: border-box;
      font-size: unset;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: white;
    }
  }

  .noneData {
    padding-top: 90px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 160px;
      height: 160px;
    }
    > div {
      &:nth-child(2) {
        // color: red;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
      &:nth-child(3) {
        text-align: center;
        font-size: 14px;
        margin-top: 4px;
        margin-bottom: 30px;
      }
      &:nth-child(4) {
        width: 158px;
        height: 40px;
        opacity: 1;
        border-radius: 24px;
        background: rgba(67, 110, 255, 1);
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 40px;
        color: white;
      }
    }
  }

  .guideCard {
    box-sizing: border-box;
    margin: 15px;
    padding: 15px;
    background: white;
    border-radius: 12px;
    .uploadTips {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      > span {
        &:nth-child(1) {
          font-size: 16px;
          font-weight: 400;
        }
        &:nth-child(2) {
          margin-left: 6px;
          font-size: 12px;
          font-weight: 400;
        }
      }
      img {
        width: 14px;
        height: 14px;
        margin-left: 4px;
      }
    }

    .uploadListItem {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .uploadedPic {
        width: 86px;
        height: 86px;
        overflow: hidden;
        margin-right: 6px;
        position: relative;
        flex-shrink: 0;
        img {
          width: 86px;
          height: 86px;
          object-fit: cover;
          border-radius: 6px;
        }
        .uploadLoading {
          position: absolute;
          width: 86px;
          height: 86px;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 22px;
            height: 22px;
            animation: spin 1s infinite linear;
          }
        }
      }

      .description {
        height: 86px;
        margin-right: 6px;
        flex-grow: 1;
        > div {
          word-break: break-word;
        }
        textarea {
          width: 100%;
          height: 86px;
          padding: 8px;
          box-sizing: border-box;
          border-radius: 6px;
          background: rgba(244, 247, 249, 1);
          border: 1px solid rgba(234, 238, 238, 1);
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          resize: none;
          &::placeholder {
            color: rgba(151, 152, 171, 1);
          }
        }
        .noneDesc {
          color: rgba(151, 152, 171, 1);
        }
      }

      .deleteUploaded {
        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    .uploadTrigger {
      margin-top: 10px;
      width: 86px;
      height: 86px;
      img {
        width: 86px;
        height: 86px;
      }
    }
  }

  .bottomBtn {
    background: transparent;
    position: absolute;
    bottom: 24px;
    padding: 0 24px;
    box-sizing: border-box;
    width: 100%;

    &.submitBtn {
      > div {
        height: 46px;
        line-height: 46px;
        background: rgba(67, 110, 255, 1);
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: white;
        border-radius: 23px;
        &.disabled {
          background: rgba(203, 213, 251, 1);
        }
      }
    }

    &.editBtn {
      > div {
        height: 46px;
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: rgba(67, 110, 255, 1);
        border-radius: 23px;
        border: 2px solid rgba(67, 110, 255, 1);
        box-sizing: border-box;
      }
    }
  }
}
</style>
